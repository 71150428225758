var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"modal":"","centered":"","size":"lg","modal-class":"custom-modal-amg","title-tag":"h3","hide-footer":"","no-close-on-backdrop":true,"header-bg-variant":"transparent","header-class":"p-0"},on:{"hidden":_vm.closeModalHistoryAdvisor},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"header-modal"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v("HISTORY ADVISOR")])]),_c('div',{staticClass:"container-icons",class:_vm.isDarkSkin ? 'container-icons--dark' : '',on:{"click":_vm.closeModalHistoryAdvisor}},[_c('feather-icon',{staticClass:"pointer",class:_vm.isDarkSkin ? 'text-light' : 'text-primary',attrs:{"icon":"XIcon","size":"18"}})],1)])]},proxy:true}]),model:{value:(_vm.ownControl),callback:function ($$v) {_vm.ownControl=$$v},expression:"ownControl"}},[_c('b-row',{staticClass:"client-info"},[_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"mr-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v("Client | "+_vm._s(_vm.client.client_name))])])]),_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"ml-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v("Program | "+_vm._s(_vm.client.program_name))])])])],1),_c('b-table',{ref:"refClientsList",staticClass:"table-new-customization custom-table",attrs:{"items":_vm.clientDashboardService,"fields":_vm.fields,"primary-key":"id","table-class":"text-nowrap","show-empty":"","sticky-header":"50vh","busy":_vm.isBusy},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(observation)",fn:function(data){return [_c('div',[(
              Boolean(data.item.observation)
                ? data.item.observation.length > 40
                : false
            )?_c('span',[_vm._v(" "+_vm._s(data.item.observation.substring(0, 40))+"... ")]):_c('span',[_vm._v(_vm._s(data.item.observation))])])]}},{key:"cell(advisor_name)",fn:function(data){return [_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-3 p-0"},[_c('b-avatar',{attrs:{"variant":"info","src":data.item.user_image_advisor
                  ? data.item.user_image_advisor
                  : require('@/assets/images/logo/amg_logo.svg')}})],1),_c('div',{staticClass:"col-8 px-1"},[_c('span',[_vm._v(_vm._s(data.item.advisor_name))])])])]}},{key:"cell(creator_name)",fn:function(data){return [_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-2 p-0"},[_c('b-avatar',{attrs:{"variant":"info","src":data.item.user_image_created
                  ? data.item.user_image_created
                  : require('@/assets/images/logo/amg_logo.svg')}})],1),_c('div',{staticClass:"col-9 px-1"},[_c('span',[_vm._v(_vm._s(data.item.creator_name))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm._f("myGlobalDay")(data.item.created_at)))])])])]}},{key:"cell(updater_name)",fn:function(data){return [(data.item.updated_at != null)?_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-2 p-0"},[_c('b-avatar',{attrs:{"variant":"info","src":data.item.user_image_updated
                  ? data.item.user_image_updated
                  : require('@/assets/images/logo/amg_logo.svg')}})],1),_c('div',{staticClass:"col-9 px-1"},[_c('span',[_vm._v(_vm._s(data.item.updater_name))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm._f("myGlobalDay")(data.item.updated_at)))])])]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }