<template>
  <div>
    <b-modal
      modal
      centered
      title="HISTORY STATUS"
      v-model="openModalHistory"
      size="lg"
      modal-class="custom-modal-amg"
      hide-footer
      header-bg-variant="transparent"
      @hidden="closeModalHistory"
      title-tag="h3"
      :no-close-on-backdrop="true"
      header-class="p-0"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            History Status
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="closeModalHistory"
            />
          </div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="my-1 px-">
          <!-- Client -->
          <b-col cols="6">
            <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
              <p
                class="m-0"
                style="gap: 5px"
              >
                Client | {{ nameClient }}
              </p>
            </div>
          </b-col>
          <!-- Program -->
          <b-col cols="6">
            <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
              <p
                class="m-0"
                style="gap: 5px"
              >
                Service | {{ nameProgram }}
              </p>
            </div>
          </b-col>
        </b-row>
        <!-- Table -->
        <b-table
          ref="refClientsList"
          :items="historys"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy="isBusy"
          class="table-new-customization custom-table"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(status)="data">
            <feather-icon
              :icon="data.item.icon"
              size="12"
              :style="
                'color: ' +
                data.item.color +
                '; background-color: ' +
                data.item.color +
                '; border-radius: 50%;'
              "
            />
            <span v-if="deployedPaymentsPrograms.includes(data.item.program_id) && data.item.parent_id">
              <span :style="'color: ' + data.item.color">
                {{ data.item.parent_name }}
              </span>
              <span>
                {{ "[ " + data.item.name + " ]" }}
              </span>
            </span>

            <span v-else>
              {{ data.item.name }}
            </span>
          </template>
          <template #cell(observation)="data">
            <change-sms
              v-if="data.item.observation"
              :sms-data="data.item.observation"
              :text-length="20"
              type-show-more="span"
            />
          </template>
          <template #cell(creator_name)="data">
            <div>
              {{ data.item.creator_name }}
            </div>
            <span>{{ data.item.created_at | myGlobalDay }}</span>
          </template>
          <template #cell(updated_at)="data">
            <div>
              {{ data.item.updater_name }}
            </div>
            <span v-if="data.item.updated_at != null">{{
              data.item.updated_at | myGlobalDay
            }}</span>
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
// Import Services
import ClientDashboardService from "../../services/clients.dashboard.services";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";
export default {
  components: {
    ChangeSms,
  },
  props: {
    modalHistory: {
      type: Boolean,
    },
    nameProgram: {
      type: String,
    },
    nameClient: {
      type: String,
    },
    account_id: {
      type: String,
    },
  },
  computed: {
    styleIcon(status) {
      return (
        "color: " +
        status.color +
        "; background-color: " +
        status.color +
        "; border-radius: 50%;"
      );
    },
  },
  data() {
    return {
      isBusy: false,
      openModalHistory: this.modalHistory,
      deployedPaymentsPrograms: [],
      historys: [],
      fields: [
        { key: "status", label: "Status" },
        { key: "observation", label: "Observation" },
        { key: "creator_name", label: "Created By" },
        { key: "updated_at", label: "Updated By" },
      ],
    };
  },
  methods: {
    closeModalHistory() {
      this.$emit("closeModalHistory");
    },

    historyStatus: async function () {
      try {
        this.isBusy = true;
        const data = await ClientDashboardService.historyStatus({
          id: this.$route.params.idClient
            ? this.$route.params.idClient
            : this.account_id,
        });
        this.isBusy = false;
        this.historys = data.data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with History!"
        );
        console.log("something went wrong with historyStatus", error);
      }
    },
    async getDeployedNewPaymentsPrograms() {
      try {
        const data = await ClientDashboardService.getDeployedNewPaymentsPrograms();
        this.deployedPaymentsPrograms = data.map(program => program.id);
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    await Promise.all([
      this.historyStatus(),
      this.getDeployedNewPaymentsPrograms(),
    ]);
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
</style>
