<template>
  <div>
    <b-modal
      v-model="ownControl"
      modal
      centered
      size="lg"
      modal-class="custom-modal-amg"
      title-tag="h3"
      hide-footer
      :no-close-on-backdrop="true"
      header-bg-variant="transparent"
      header-class="p-0"
      @hidden="closeModalHistoryAdvisor"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>HISTORY ADVISOR</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModalHistoryAdvisor"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <b-row class="client-info">
        <!-- Client -->
        <b-col cols="6" class="p-0">
          <div
            class="mr-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Client | {{ client.client_name }}</p>
          </div>
        </b-col>
        <b-col cols="6" class="p-0">
          <div
            class="ml-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Program | {{ client.program_name }}</p>
          </div>
        </b-col>
        <!-- <b-col cols="6" /> -->
      </b-row>
      <!-- Table -->
      <b-table
        ref="refClientsList"
        :items="clientDashboardService"
        :fields="fields"
        primary-key="id"
        table-class="text-nowrap"
        show-empty
        sticky-header="50vh"
        class="table-new-customization custom-table"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <!-- Column OBS -->
        <template #cell(observation)="data">
          <div>
            <!-- see more if lenght is more 40 -->
            <span
              v-if="
                Boolean(data.item.observation)
                  ? data.item.observation.length > 40
                  : false
              "
            >
              {{ data.item.observation.substring(0, 40) }}...
            </span>
            <!-- see all if lenght is less 40 -->
            <span v-else>{{ data.item.observation }}</span>
          </div>
        </template>
        <!-- Column ADVISOR -->
        <template #cell(advisor_name)="data">
          <div class="row align-items-center">
            <div class="col-3 p-0">
              <b-avatar
                variant="info"
                :src="
                  data.item.user_image_advisor
                    ? data.item.user_image_advisor
                    : require('@/assets/images/logo/amg_logo.svg')
                "
              ></b-avatar>
            </div>
            <div class="col-8 px-1">
              <span>{{ data.item.advisor_name }}</span>
            </div>
          </div>
        </template>
        <!-- Column CREATED BY -->
        <template #cell(creator_name)="data">
          <div class="row align-items-center">
            <div class="col-2 p-0">
              <b-avatar
                variant="info"
                :src="
                  data.item.user_image_created
                    ? data.item.user_image_created
                    : require('@/assets/images/logo/amg_logo.svg')
                "
              ></b-avatar>
            </div>
            <div class="col-9 px-1">
              <span>{{ data.item.creator_name }}</span> <br />
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </div>
          </div>
        </template>
        <!-- Column UPDATED BY -->
        <template #cell(updater_name)="data">
          <div
            class="row align-items-center"
            v-if="data.item.updated_at != null"
          >
            <div class="col-2 p-0">
              <b-avatar
                variant="info"
                :src="
                  data.item.user_image_updated
                    ? data.item.user_image_updated
                    : require('@/assets/images/logo/amg_logo.svg')
                "
              ></b-avatar>
            </div>
            <div class="col-9 px-1">
              <span>{{ data.item.updater_name }}</span> <br />
              <span>{{ data.item.updated_at | myGlobalDay }}</span>
            </div>
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// Import Services
import ClientDashboardService from "../../services/clients.dashboard.services";

export default {
  data() {
    return {
      ownControl: false,
      openModalHistoryAdvisor: this.modalHistoryAdvisor,
      isBusy: false,
      fields: [
        { key: "advisor_name", label: "Advisor" },
        { key: "observation", label: "Observation" },
        { key: "creator_name", label: "Created By" },
        { key: "updater_name", label: "Updated By" },
      ],
      historys: [],
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.ownControl = true;
  },

  methods: {
    closeModalHistoryAdvisor() {
      this.$emit("closeModalHistoryAdvisor");
    },
    async clientDashboardService() {
      try {
        this.isBusy = true;
        const response = await ClientDashboardService.historyAdvisor({
          id: this.$route.params.idClient,
        });
        this.isBusy = false;
        if (response.status === 200) {
          this.historys = response.data;
        }
        return this.historys;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with advisor!"
        );
      }
    },
  },
};
</script>
<style scoped>
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
  color: #fff;
}
</style>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
