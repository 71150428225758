import { render, staticRenderFns } from "./Binnacle.vue?vue&type=template&id=151a7934&scoped=true"
import script from "./Binnacle.vue?vue&type=script&lang=js"
export * from "./Binnacle.vue?vue&type=script&lang=js"
import style0 from "./Binnacle.vue?vue&type=style&index=0&id=151a7934&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "151a7934",
  null
  
)

export default component.exports