<template>
  <div>
    <b-modal
      ref="scoreModal"
      v-model="ownControl"
      title="SCORES"
      title-tag="h3"
      size="caro"
      hide-footer
      @hidden="closeModal"
    ><div>

       <!-- Titles -->
       <b-row class="mt-1 px-">

         <!-- Client -->
         <b-col cols="6">
           <h5 class="text-left">
             Client
           </h5>
           <p
             class="rounded text-primary border-primary font-medium-1 text-center py10"
           >
             {{ client.client_name }}
           </p>
         </b-col>
         <!-- Account -->
         <b-col cols="6">
           <h5 class="text-left">
             Account
           </h5>
           <p
             class="rounded text-primary border-primary font-medium-1 text-center py10"
           >
             {{ client.account }}
           </p>
         </b-col>
       </b-row>

       <b-row>
         <b-col>
           <b-table
             ref="refScoresList"
             :items="allscores"
             :fields="fields"
             primary-key="id"
             table-class="text-nowrap"
             responsive="sm"
             show-empty
             style="margin-bottom: 0px"
             sticky-header="36vh "
             :busy.sync="isBusy"
           >
             <template #table-busy>
               <div class="text-center text-primary my-2">
                 <b-spinner class="align-middle mr-1" />
                 <strong>Loading ...</strong>
               </div>
             </template>

             <template #head(date)>
               <span>Date</span>
             </template>

             <template #head(file)>
               <span>File</span>
             </template>
             <template #head(equifax)>
               <span
                 style="color:#981e32;font-weight: bold;text-align:center;font-style: italic;"
               >EQUIFAX</span>
             </template>

             <template #head(experian)>
               <span
                 style="color:#0566b7;font-weight: bold;text-align:center"
               >Experian</span>
             </template>
             <template #head(trans_union)>
               <template style="font-weight: bold;text-align:center">
                 <span>Trans</span>
                 <span style="color:#4d917a"> Union</span>
               </template>

             </template>
             <template #cell(date)="data">
               <template>
                 {{ data.item.date | myGlobal }}
               </template>

             </template>

             <template #cell(equifax)="data">
               <template>

                 <span style="color:#981e32;font-weight: bold;font-size: 15px;border-top: none;text-align:center">{{ data.item.equifax }}  </span>
               </template>

             </template>

             <template #cell(experian)="data">
               <template>

                 <span style="color:#0566b7;font-weight: bold;font-size: 15px;border-top: none;text-align:center">{{ data.item.experian }}  </span>
               </template>

             </template>

             <template #cell(trans_union)="data">
               <template>

                 <span style="color:#4d917a;font-weight: bold;font-size: 15px;border-top: none;text-align:center">{{ data.item.transunion }}  </span>
               </template>

             </template>

             <template #cell(created_by)="data">
               <template>

                 <span>{{ data.item.username }}</span><br><span>{{ data.item.created_at | myGlobalDay }}</span>
               </template>

             </template>
             <template #cell(updated_by)="data">
               <template>

                 <span>{{ data.item.updatename }}</span><br><span v-if="data.item.updated_at != null">{{ data.item.updated_at | myGlobalDay }}</span>
               </template>

             </template>

             <template #cell(actions)="data">
               <template>

                 <feather-icon
                   class="text-warning mr-1 cursor-pointer"
                   icon="EditIcon"
                   size="18"
                   @click="openEditScore(data.item.id)"
                 />
                 <feather-icon
                   class="text-danger cursor-pointer"
                   size="18"
                   icon="TrashIcon"
                   @click="deletedScore(data.item.id)"
                 />

               </template>

             </template>
           </b-table>
         </b-col>
       </b-row>

     </div>
      <modal-add-score
          :idscore="scoreId"
        v-if="modalAddScoreOn"
        @close="closeModalAddScore"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientDashboardService from '@/views/commons/components/clients/dashboard/services/clients.dashboard.services'
import ModalAddScore
from '@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalAddScore.vue'

export default {
  components: {
    ModalAddScore,
  },

  data() {
    return {
      scoreId: null,
      modalAddScoreOn: false,
      ownControl: false,
      isBusy: false,
      allscores: [],

      fields: [
        { key: 'date', label: 'Date' },
        { key: 'equifax', label: 'Equifax' },
        { key: 'experian', label: 'Experian' },
        { key: 'trans_union', label: 'Trans Union' },
        { key: 'created_by', label: 'Created By' },
        { key: 'updated_by', label: 'Updated By' },
        { key: 'actions', label: 'Actions' },

      ],
    }
  },
  computed: {

    ...mapGetters({
      client: 'DebtSolutionClients/G_CLIENTS',
      currentUser: 'auth/currentUser',
    }),
  },
  async  created() {
    this.ownControl = true
    await this.scores()


  },
  methods: {

    openEditScore(id) {
      this.scoreId = id
      this.modalAddScoreOn = true
    },

    async closeModalAddScore() {
      await  this.scores()
      this.modalAddScoreOn = false
    },
    closeModal() {

      this.$emit('close')
    },
    async deletedScore(idscore) {
      const params = {
        id_user: this.currentUser.user_id,
        idscore,

      }
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          await ClientDashboardService.deleteScore(params)
          await this.scores()

          this.showSuccessSwal()
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    async scores() {
      try {
        const data = await ClientDashboardService.allScore({
          id: this.client.lead_id,
        })
        if (data.status === 200) {
          this.allscores = data.data
        }

        return this.allscores
      } catch (error) {
        console.log(error)
        this.showToast(
          'danger',
          'top-right',
          'Error',
          'XIcon',
          'Something went wrong with score!',
        )
      }
    },

  },

}
</script>

<style scoped>

</style>
