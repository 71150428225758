<template>
  <div>
    <b-modal
      v-model="ownControl"
      modal
      size="lg"
      modal-class="custom-modal-amg"
      hide-footer
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="closeModalTracking"
      header-bg-variant="transparent"
      header-class="p-0"
      centered
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>{{ `TRACKING CHANGE ${namefiels}` }}</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModalTracking"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <b-row class="client-info">
        <!-- Client -->
        <b-col cols="6" class="p-0">
          <div
            class="mr-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Client | {{ client.client_name }}</p>
          </div>
        </b-col>
        <b-col cols="6" class="p-0">
          <div
            class="ml-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Program | {{ client.program_name }}</p>
          </div>
        </b-col>
        <!-- <b-col cols="6" /> -->
      </b-row>
      <!-- Table -->
      <b-table
        responsive
        ref="refClientsList"
        :items="allTrackingFields"
        :fields="fields"
        primary-key="id"
        table-class="text-nowrap"
        show-empty
        sticky-header="70vh"
        :busy="isBusy"
        class="table-new-customization custom-table"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(general)="data">
          <span v-if="data.item.type === 'Charge'"
            ><span
              v-if="data.item.fields === '1'"
              style="color: red; font-weight: bolder"
              >Denied</span
            >

            <span v-else style="color: green; font-weight: bolder">Active</span>
          </span>
          <span v-else>
            <span
              v-if="
                data.item.type == 'SSN' ||
                data.item.type == 'ITIN' ||
                data.item.type == 'CPN'
              "
            >
              <span
                v-if="
                  roleCurrentUser == 1 ||
                  roleCurrentUser == 2 ||
                  roleCurrentUser == 3 ||
                  roleCurrentUser == 6
                "
              >
                <span v-if="eyetrue == true">{{
                  data.item.fields_secret
                }}</span>
                <span v-if="eyefalse == true">{{ data.item.fields }}</span>
              </span>
              <span v-else>
                <span v-if="eyetrue == true">{{
                  data.item.fields_secret
                }}</span>
              </span></span
            ><span v-else>{{ data.item.fields }}</span></span
          >
          <span
            v-if="
              (data.item.type == 'SSN' ||
                data.item.type == 'ITIN' ||
                data.item.type == 'CPN') &&
              (roleCurrentUser == 1 || roleCurrentUser == 2)
            "
          >
            <b-button
              v-if="eyetrue == true"
              type="button"
              style="float: right"
              @click="eyeclick(1)"
              ><feather-icon icon="EyeIcon" class="text-success"
            /></b-button>
            <b-button
              v-if="eyefalse == true"
              type="button"
              style="float: right"
              @click="eyeclick(2)"
              ><feather-icon icon="XCircleIcon" class="text-danger" /></b-button
          ></span>
        </template>
        <template #cell(created_name_user)="data">
          <div class="row align-items-center">
            <div class="col-2 p-0">
              <b-avatar
                variant="info"
                :src="
                  data.item.user_image_created
                    ? data.item.user_image_created
                    : require('@/assets/images/logo/amg_logo.svg')
                "
              ></b-avatar>
            </div>
            <div class="col-9 px-1">
              <span>{{ data.item.created_name_user }}</span> <br />
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </div>
          </div>
        </template>
        <template #cell(updated_name_user)="data">
          <div
            class="row align-items-center"
            v-if="data.item.updated_at != null"
          >
            <div class="col-2 p-0">
              <b-avatar
                variant="info"
                :src="
                  data.item.user_image_updated
                    ? data.item.user_image_updated
                    : require('@/assets/images/logo/amg_logo.svg')
                "
              ></b-avatar>
            </div>
            <div class="col-9 px-1">
              <span>{{ data.item.updated_name_user }}</span> <br />
              <span>{{ data.item.updated_at | myGlobalDay }}</span>
            </div>
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// Import Service
import ClientDashboardService from "../../services/clients.dashboard.services";

export default {
  props: {
    type: {
      type: [String, Number],
    },
    namefiels: {
      type: String,
    },
  },
  data() {
    return {
      ownControl: false,
      openModalTracking: this.modalTrackingMobile,
      data: [],
      isBusy: false,
      fields: [
        { key: "general", label: `${this.namefiels}` },
        { key: "created_name_user", label: "Created By" },
        { key: "updated_name_user", label: "Updated By" },
      ],
      number: "",
      eyetrue: true,
      eyefalse: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    roleCurrentUser() {
      return this.currentUser.role_id;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.ownControl = true;
  },
  methods: {
    eyeclick(num) {
      this.number = num;
      if (this.number === 1) {
        this.eyetrue = false;
        this.eyefalse = true;
      } else {
        this.eyetrue = true;
        this.eyefalse = false;
      }
    },
    closeModalTracking() {
      this.$emit("closeModalTracking");
    },
    async allTrackingFields() {
      try {
        const params = {
          id_lead: this.client.lead_id,
          typee: this.type,
          id_module:
            this.moduleId == 4
              ? this.convertProgramToModule(this.client.program_id)
              : this.moduleId,
        };
        this.isBusy = true;
        const response = await ClientDashboardService.allTrackingFields(params);
        this.isBusy = false;
        if (response.status === 200) {
          this.data = response.data;
          return this.data;
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with tracking!"
        );
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
  color: #fff;
}
</style>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
