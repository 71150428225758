<template>
  <b-modal
    ref="chat"
    title="Chat"
    hide-header
    hide-footer
    scrollbar
    centered
    @hidden="closeModal()"
    v-model="showModal"
    size="xlg"
    :modal-class="
      isDarkSkin
        ? 'bg-modal-binnacle-dark bg-modal-binnacle-ca'
        : 'bg-modal-binnacle-light bg-modal-binnacle-ca'
    "
  >
    <!--BUTTON CLOSE MODAL-->
    <b-button
      variant="white"
      class="bg-white position-absolute"
      style="right: -10px; top: -10px; z-index: 20"
      size="sm"
      @click="closeModal"
    >
      <feather-icon icon="XIcon" size="20" />
    </b-button>
    <b-row class="p-0">
      <b-col sm="12" md="8" lg="9">
        <div
          class="chat-container"
          :class="isDarkSkin ? 'bg-chat-dark' : 'bg-chat-light'"
          ref="scrollList"
        >
          <div class="chat-messages">
            <template v-for="(item, index) in comments">
              <div
                :key="index"
                class="message"
                :class="
                  item._user_id == getUserId
                    ? 'message-sender '
                    : 'message-receiver'
                "
              >
                <div class="row align-items-start">
                  <b-avatar
                    :variant="isDarkSkin ? 'warning' : 'info'"
                    icon="person-fill
"
                    :class="item._user_id == getUserId ? 'order-2' : 'order-1'"
                  ></b-avatar>
                  <div
                    :class="
                      item._user_id == getUserId
                        ? 'order-1 mr-1'
                        : 'order-2 ml-1'
                    "
                  >
                    <p
                      class="p-0 m-0"
                      :class="
                        item._user_id == getUserId ? 'text-right' : 'text-left'
                      "
                      style="font-weight: bold"
                    >
                      {{ item._user_id == getUserId ? "You" : item.user_name }}
                    </p>
                    <div
                      :class="
                        item._user_id == getUserId
                          ? 'content-message-sender'
                          : 'content-message-receiver'
                      "
                    >
                      <template v-if="item.is_file">
                        <div
                          v-if="
                            ['png', 'jpge', 'jpg', 'jpeg'].includes(
                              item.file_type
                            )
                          "
                          :style="containerImg(item)"
                          @click="openImage(item.file_type, item)"
                        ></div>
                        <div
                          v-else
                          @click="openImage(item.file_type, item)"
                          class="message-text"
                        >
                          <p class="cursor-pointer link-file">
                            {{ item._content }}
                          </p>
                        </div>
                      </template>
                      <template v-else>
                        <div class="message-text">
                          {{ item._content }}
                        </div>
                      </template>

                      <div class="message-date text-muted text-right col-12">
                        {{ item.date_time | myFromNow }}
                        <template v-if="item._user_id == getUserId">
                          <tabler-icon
                            v-if="item.viewed"
                            size="18"
                            class="text-primary"
                            icon="ChecksIcon"
                          />
                          <tabler-icon
                            v-else
                            size="18"
                            class="text-secondary"
                            icon="ChecksIcon"
                          />
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="chat-input">
          <div class="input-container">
            <input v-model="comment.content" @keyup.enter="insertMessage" />
            <feather-icon
              icon="PaperclipIcon"
              size="22"
              class="cursor-pointer chat-action-paper-icon"
              @click="modalFiles = true"
            />
            <feather-icon
              size="22"
              icon="SendIcon"
              class="chat-action-bar-icon cursor-pointer"
              @click="insertMessage"
            />
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="4" lg="3">
        <div :class="isDarkSkin ? 'black' : 'light'" class="rounded card">
          <div class="p-2">
            <h3 class="text-primary" style="font-weight: bold">Information</h3>
            <hr style="border: 1px solid #0090e7" />
            <b-row class="my-1">
              <span class="attr-text col-md-5">CLIENT</span>
              <div class="col-md-7">
                <strong>{{ client_information.name_client }}</strong>
              </div>
            </b-row>
            <b-row class="my-1">
              <span class="attr-text col-md-5">MODULE</span>
              <div class="col-md-7">
                <strong class="text-capitalize">{{
                  client_information.module
                }}</strong>
              </div>
            </b-row>
            <b-row class="my-1">
              <span class="attr-text col-md-5">CREATED AT</span>
              <div class="col-md-7">
                <strong>{{ client_information.created_at | myGlobal }}</strong>
              </div>
            </b-row>

            <b-row class="my-1">
              <span class="attr-text col-md-5">ADVISOR</span>
              <div class="col-md-7">
                <strong>{{ client_information.name_advisor }}</strong>
              </div>
            </b-row>

            <b-row class="my-1">
              <span class="attr-text col-md-5">TYPE</span>
              <div class="col-md-7">
                <strong>{{ client_information.name_charge }}</strong>
              </div>
            </b-row>
          </div>
        </div>
        <div :class="isDarkSkin ? 'black' : 'light'" class="rounded mt-1 card">
          <b-tabs
            :content-class="otherFiles.length > 0 ? 'my-3' : 'my-3'"
            fill
            justified
          >
            <b-tab title="Files" class="text-center">
              <b-row
                align-h="center"
                :style="otherFiles.length > 0 ? 'height: 150px !important' : ''"
                style="overflow: auto !important"
              >
                <template v-if="otherFiles.length > 0">
                  <template v-for="(item, index) in otherFiles">
                    <b-col :key="index" md="3" class="px-1 cursor-pointer">
                      <img
                        :src="getImageFile(item.content)"
                        width="50"
                        @click="forceDownload(item.file_path, item.content)"
                        class="cursor-pointer"
                        :alt="item.content"
                      />
                      <p
                        class="text-truncate link-file cursor-pointer"
                        v-b-tooltip.hover
                        :title="item.content"
                        @click="forceDownload(item.file_path, item.content)"
                      >
                        {{ item.content }}
                      </p>
                    </b-col>
                  </template></template
                >
                <template v-else>
                  <b-icon icon="folder-x" font-scale="5"></b-icon>
                </template>
              </b-row>
            </b-tab>

            <b-tab title="Images" lazy class="text-center">
              <b-row
                align-h="center"
                :style="images.length > 0 ? 'height: 150px !important' : ''"
                style="overflow: auto !important"
              >
                <template v-if="images.length > 0">
                  <template v-for="(item, index) in images">
                    <b-col :key="index" md="3" class="px-1">
                      <img
                        :src="getImageFile(item.content)"
                        width="50"
                        @click="forceDownload(item.file_path, item.content)"
                        class="cursor-pointer"
                        :alt="item.content"
                      />
                      <p
                        class="text-truncate link-file cursor-pointer"
                        v-b-tooltip.hover
                        :title="item.content"
                        @click="forceDownload(item.file_path, item.content)"
                      >
                        {{ item.content }}
                      </p>
                    </b-col>
                  </template></template
                >
                <template v-else>
                  <b-icon icon="folder-x" font-scale="5"></b-icon>
                </template> </b-row
            ></b-tab>
          </b-tabs>
        </div>
        <div :class="isDarkSkin ? 'black' : 'light'" class="rounded mt-1 card">
          <div class="px-1 mt-1">
            <h3 class="text-primary" style="font-weight: bold">
              Tracking status
            </h3>
            <hr style="border: 1px solid #0090e7" />
          </div>
          <template v-if="commentsStatus.length > 0">
            <template v-for="(item, index) in commentsStatus">
              <div class="card tracking mx-1 my-0" :key="index">
                <div class="timeline py-1">
                  <div :key="index" class="timeline-event">
                    <div class="timeline-event-header">
                      {{ item.created_at | myGlobalDay }}
                    </div>
                    <div class="timeline-event-body">
                      <h4>{{ getTitleStatus(item.status_application) }}</h4>
                      <span>{{ item.content || "-" }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="d-flex justify-content-center py-2">
              <p>Empty</p>
            </div>
          </template>
        </div>
      </b-col>
    </b-row>
    <ModalUploadFiles
      v-if="modalFiles"
      :showModal="modalFiles"
      @sendFiles="sendFiles"
      @closeModal="closeModalFiles"
    />
    <CoolLightBox
      :items="itemImage"
      :index="showImage"
      :effect="'fade'"
      @close="showImage = null"
    />
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import BinnacleService from "@/views/commons/components/applications/views/components/binnacle/service/binnacle-service";
import CoolLightBox from "vue-cool-lightbox";
import ModalUploadFiles from "@/views/commons/components/applications/views/components/binnacle/ModalUploadFiles.vue";
export default {
  components: { ModalUploadFiles, CoolLightBox },
  props: {
    client_information: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      showModal: true,
      comment: {
        content: "",
        type: 1,
        is_file: false,
        file: null,
      },
      comments: [],
      commentsStatus: [],
      modalFiles: false,
      files: [],
      showImage: null,
      itemImage: [],
      show: true,
    };
  },
  async mounted() {
    this.initializePusherChannel();
    this.getComments();
    this.getCommentsStatus();
    await this.getFilesBinnacle();
    const channel = socket.channel("application-notifications", {});

    channel.bind("client-send-message", (payload) => {
      if (
        this.client_information.section == "application" &&
        payload._application_id == this.client_information.app_id
      ) {
        this.comments.push(payload);
        this.markMessageAsViewed(payload);
        this.scrollCommentsBottom();
      }
      if (
        this.client_information.section == "appointment" &&
        payload._id_appointment == this.client_information.id
      ) {
        this.comments.push(payload);
        this.markMessageAsViewed(payload);
        this.scrollCommentsBottom();
      }
    });
    this.markUnviewedMessages();
  },
  beforeDestroy() {
    this.closePusherChannel();
  },
  methods: {
    initializePusherChannel() {
      const channel = socket.channel("application-notifications", {});

      channel.bind("client-mark-as-viewed", ({ messageId }) => {
        const message = this.comments.find((m) => m._id === messageId);
        if (message) {
          message.viewed = true;
        }
      });
    },
    closePusherChannel() {
      const channel = socket.channel("application-notifications", {});
      channel.unbind_all();
    },
    triggerMessageSocket(message) {
      const channel = socket.channel("application-notifications", {});
      if (channel) {
        channel.trigger("client-send-message", message);
      }
    },
    async getComments() {
      try {
        this.addPreloader();
        const params = {
          _application_id: this.client_information.app_id || null,
          _id_appointment: this.client_information.id || null,
          _type: this.client_information.section,
        };
        const response = await BinnacleService.getCommentsBinnacle(params);
        this.comments = response;
        this.scrollCommentsBottom();
      } catch (e) {
      } finally {
        this.removePreloader();
      }
    },

    async getCommentsStatus() {
      try {
        this.addPreloader();
        const params = {
          _application_id: this.client_information.app_id,
          _id_appointment: this.client_information.id,
          _type: this.client_information.section,
        };
        const response = await BinnacleService.getCommentsStatus(params);
        this.commentsStatus = response;
      } catch (e) {
      } finally {
        this.removePreloader();
      }
    },

    scrollCommentsBottom() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.scrollList.scrollTop = this.$refs.scrollList.scrollHeight;
        }, 100);
      });
    },
    async insertMessage(event) {
      if (event.shiftKey) {
        event.preventDefault();
        return;
      }

      if (this.comment.content == null || this.comment.content == "") {
        return;
      }
      this.comment.content = this.comment.content.trim();
      try {
        const tempContent = this.comment.content;
        const params = {
          _application_id: this.client_information.app_id || null,
          _id_appointment: this.client_information.id || null,
          _user_id: this.currentUser.user_id,
          _content: tempContent,
          _type: this.client_information.section,
        };
        const result = await BinnacleService.insertMessageBinnacle(params);
        const newMessage = {
          _application_id: this.client_information.app_id || null,
          _user_id: this.currentUser.user_id,
          _id_appointment: this.client_information.id || null,
          _content: this.comment.content,
          date_time: new Date(),
          user_name: this.currentUser.fullName,
          _id: result[0].id,
          viewed: false,
          isSender: true,
        };
        this.triggerMessageSocket(newMessage);
        this.comments.push(newMessage);
        this.scrollCommentsBottom();
        this.comment.content = tempContent;
        this.comment.content = null;
      } catch (e) {
        this.$swal.fire({
          icon: "error",
          title: e,
        });
      }
    },
    async sendFiles(files) {
      if (files.length === 0) return;
      this.addPreloader();

      try {
        let formData = new FormData();
        formData.append("_application_id", this.client_information.app_id);
        formData.append("_user_id", this.currentUser.user_id);
        formData.append("_type", this.client_information.section);
        formData.append("_id_appointment", this.client_information.id);

        files.forEach((file) => {
          formData.append("files[]", file);
        });

        const { data } = await BinnacleService.uploadFilesBinnacle(formData);
        this.comments.push(...data);

        const newMessages = data.map((fileInfo) => {
          return {
            _application_id: this.client_information.app_id,
            _id_appointment: this.client_information.id,
            _user_id: fileInfo._user_id,
            _content: fileInfo._content,
            date_time: new Date(),
            _type: 1,
            user_name: this.currentUser.fullName,
            is_file: true,
            file_path: fileInfo.file_path,
            file_type: fileInfo.file_type,
            _id: fileInfo._id,
            viewed: false,
            isSender: true,
          };
        });

        newMessages.forEach((newMessage) => {
          this.triggerMessageSocket(newMessage);
        });

        this.closeModalFiles();
        this.scrollCommentsBottom();
        this.removePreloader();
        this.$emit("refresh");
        this.getFilesBinnacle();
      } catch (e) {
        this.$swal.fire({
          icon: "error",
          title: e,
        });
        this.removePreloader();
      }
    },

    forceDownload(url, filename) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async getFilesBinnacle() {
      const params = {
        _id: this.client_information.app_id || null,
        _appointment_id: this.client_information.id || null,
        _type: this.client_information.section || null,
      };
      const { data } = await BinnacleService.getFilesBinnacle(params);
      this.files = data;
    },
    openImage(extension, image) {
      if (this.isImage(extension)) {
        this.itemImage = [image.file_path];
        this.showImage = 0;
      } else {
        this.forceDownload(image.file_path, image.file_name);
      }
    },
    getImageFile(filename) {
      const extension = filename.split(".").pop().toLowerCase();

      const extensionMappings = {
        jpg: "jpg.png",
        png: "jpg.png",
        jpeg: "jpg.png",
        gif: "jpg.png",
        xls: "excel.png",
        xlsx: "excel.png",
        html: "html",
        pdf: "pdf.png",
        docx: "doc.png",
        pptx: "ppt.jpg",
        ppt: "ppt.jpg",
        default: "archivodesc.png",
      };

      const getImageFile =
        extensionMappings[extension] || extensionMappings.default;
      return `/assets/images/drag-drop/ext_files/${getImageFile}`;
    },
    isOtherFile(fileType) {
      return !this.isImage(fileType);
    },
    isImage(fileType) {
      const imageExtensions = ["jpg", "png", "jpeg", "gif"];
      return imageExtensions.includes(fileType.toLowerCase());
    },
    containerImg(item) {
      return `background-image: url(${item.file_path}); background-size: cover; background-position: center; width: 100%; height: 100px;border-radius:10px`;
    },
    async markMessageAsViewed(message) {
      if (!message.viewed) {
        message.viewed = true;
        const channel = socket.channel("application-notifications", {});
        if (channel) {
          channel.trigger("client-mark-as-viewed", { messageId: message._id });
          const params = {
            _id: message._id,
            viewed: true,
            is_file: message.is_file,
          };
          await BinnacleService.markAsSeen(params);
        }
      }
    },
    async markUnviewedMessages() {
      const receiverId = this.currentUser.user_id;
      if (this.comments) {
        for (const message of this.comments) {
          if (
            !message.viewed &&
            !message.isSender &&
            message._user_id != receiverId
          ) {
            await this.markMessageAsViewed(message);
          }
        }
      }
    },
    closeModal() {
      this.$emit("close");
    },
    closeModalFiles() {
      this.modalFiles = false;
    },
    getTitleStatus(status) {
      let obj = {
        form: "Status form:",
        result: "Status result:",
        paid: "Status paid:",
      };

      return obj[status];
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    getUserId() {
      return this.currentUser.user_id;
    },
    images() {
      return this.files.filter((item) => this.isImage(item.file_type));
    },
    otherFiles() {
      return this.files.filter((item) => this.isOtherFile(item.file_type));
    },
  },
};
</script>
<style scoped  lang="scss" >
.black {
  background: #1a1c25;
}
.light {
  background: #f1f4f7;
}
.bg-modal-binnacle-dark .modal-body {
  background: #11111e !important;
}

.bg-modal-binnacle-dark {
  .content-message-receiver {
    background: #656565;
    border-radius: 10px 0 10px 10px;
  }
  .content-message-sender {
    background: #052440;
    border-radius: 10px 0 10px 10px;
  }
  .chat-input {
    background: #1a1c25;
    padding: 0 5px 15px 5px;
    border-top: 0px;
    border-radius: 0 0 10px 10px;
  }
  input {
    background: #17171a;
    border: 1px solid #656565;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    outline: none !important;
    padding-right: 40px;
    padding-left: 40px;
    color: #fff;
  }
  .link-file {
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
  }
  .message-date {
    color: #fff !important;
  }
  .bg-chat-dark {
    background: #1a1c25;
  }
}

.bg-modal-binnacle-light .modal-body {
  background: #e2eef8 !important;
}

.bg-modal-binnacle-light {
  .link-file {
    text-decoration: underline;
    font-weight: bold;
    color: #0090e7;
  }

  .bg-chat-light {
    background: #f1f4f7;
  }

  .content-message-receiver {
    background: #fff;
    border-radius: 0 10px 10px 10px;
  }
  .content-message-sender {
    background: #d4edff;
    border-radius: 10px 0 10px 10px;
  }

  .chat-input {
    padding: 0 5px 15px 5px;
    border-top: 0px;
    background: #f1f4f7;
    border-radius: 0 0 10px 10px;
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none !important;
    padding-right: 40px;
    padding-left: 40px;
  }
}
.bg-modal-binnacle-ca {
  .input-container {
    position: relative;
  }
  .chat-container {
    height: 85vh;
    max-height: 85vh;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    overflow-y: auto;
    border-radius: 10px 10px 0 0;
  }
  .chat-action-bar-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .chat-action-paper-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .message-sender {
    align-self: flex-end;
  }
  .message-receiver {
    align-self: flex-start;
  }
  .chat-messages {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .message {
    padding: 8px;
    border-radius: 5px;
    width: fit-content;
  }

  .message-text {
    padding: 8px;
    border-radius: 5px;
    max-width: 500px;
    overflow: hidden;
    word-wrap: break-word;
  }
}

.timeline-event {
  border-left: 2px solid #007bff; /* Timeline line color */
  padding-left: 20px;
}

.timeline-event-header {
  font-weight: bold;
}

.timeline-event-body {
  padding-left: 10px;
}
.tracking {
  margin-bottom: 5px !important;
}
</style>