<template>
  <b-modal
    v-model="onControl"
    title="Applications"
    title-tag="h3"
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    size="xl"
    hide-footer
    @hidden="close"
  >
    <b-table
      ref="applicationsClient"
      no-border-collapse
      class="position-relative"
      :fields="visibleFields"
      show-empty
      :items="items"
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(name_user)="data">
        <span
          >{{ data.item.name_user }} <br />
          {{ data.item.created_at | myGlobal }}
        </span>
      </template>
      <template #cell(name_charge)="data">
        <span class="k-font-weight-bold"> {{ data.item.name_charge }} </span>
      </template>
      <template #cell(status)="data">
        <status-app-services
          v-if="[2, 3].includes(typeService)"
          :data="data"
          :options="statusOptions"
          @addCharge="openAddCharge(data.item)"
          :service="'appointments'"
          @refreshTable="refreshTable"
        />
        <status-app
          v-else
          :data="data"
          :options="statusOptions"
          @refreshTable="refreshTable"
        />
      </template>
      <template #cell(form)="data">
        <center>
          <template v-if="typeService === 2">
            <feather-icon
              icon="ClipboardIcon"
              size="20"
              class="text-secondary text-center cursor-pointer"
              :class="
                data.item.status_paid
                  ? data.item.app_status == 'COMPLETED'
                    ? 'text-success': 'text-warning'
                  : 'text-secondary'
              "
              @click="data.item.status_paid == 1 ? openFormModal(data.item): null"
            />
          </template>
          <feather-icon
            v-else-if="[1, 'ACCEPTED'].includes(data.item.status)"
            :class="[data.item.done_form == 1 ? 'active' : 'inactive']"
            icon="ClipboardIcon"
            size="20"
            @click="openPersonalInfoModal(data.item)"
          />
        </center>
      </template>

      <template #cell(result)="data">
        <status-result
          v-if="data.item.done_form == 1"
          :data="data"
          :options="resultOptions"
        />
      </template>
      <template #cell(done_bank)="data">
        <center>
          <tabler-icon
            v-if="data.item.status_result === 1"
            icon="BuildingBankIcon"
            size="20"
            class="cursor-pointer"
            :class="
              data.item.done_bank == 1 ? 'text-success' : 'text-secondary'
            "
            @click="openBankInfo(data.item)"
          />
        </center>
      </template>
      <template #cell(paid)="data">
        <span class="text-warning text-uppercase" v-if="data.item.status_paid == 0 && typeService == 2">VERIFYING</span>
        <status-paid
          v-else
          :data="data"
          :options="paidOptions"
          @refreshTable="refreshTable"
        />
      </template>
      <template #cell(amount)="data">
        <div
          v-if="data.item.amount != null && data.item.suggets_charge != null"
        />
        <div>
          <label class="mb-0 text-dark" title="Fee"
            >Fee {{ "$" + data.item.amount }}</label
          >
        </div>
        <div>
          <label class="mb-0" title="Suggest charge"
            >Suggest {{ "$" + data.item.suggets_charge }}</label
          >
        </div>
      </template>
      <template #cell(binnacle)="{ item }">
        <tabler-icon
          badge-classes="badge-important"
          icon="BrandMessengerIcon"
          size="25"
          class="text-primary cursor-pointer"
          @click="openModalBinnacle(item)"
        />
      </template>
      <template #cell(tracking)="data">
        <div class="d-flex flex-row justify-content-around px-1">
          <feather-icon
            class="cursor-pointer text-info"
            icon="ListIcon"
            size="20"
            @click="openModalTracking(data.item)"
          />
        </div>
      </template>
      <template #custom-foot>
        <td
          :colspan="indexFieldAmount"
          :class="isDarkSkin ? 'bg-category-modal' : 'bg-secondary'"
        />
        <td
          :class="isDarkSkin ? 'bg-category-modal' : 'bg-secondary'"
          class="text-white align-middle vertical-align"
        >
          <span class="k-font-weight-bold"
            >TOTAL <span class="ml-2"> $ {{ total }}</span></span
          >
        </td>
        <td :class="isDarkSkin ? 'bg-category-modal' : 'bg-secondary'" />
      </template>
    </b-table>
    <form-modal
      v-if="showForm"
      :client-data="clientData"
      :typeService="typeService"
      @closeModal="closeFormModal"
      @refreshTable="refreshTable"
      :disabled="disabled"
    />
    <personal-info-modal
      v-if="ActivePersonalInfoModal"
      :id="idApp"
      :completed="doneForm"
      @refreshTable="refreshTable"
      @closeModal="closePersonalInfoModal"
    />
    <list-tracking-modal
      v-if="trackingController"
      :row="idApp"
      @close="closeListTrackingModal"
    />
    <create-tracking-modal
      v-if="ActiveCreateTrackingModal"
      :row="row"
      @close="ActiveCreateTrackingModal = false"
    />
    <bank-info-modal
      v-if="bankInfoController"
      :row="dataBank"
      @close="closeBankInfoModal"
    />

    <Binnacle
      v-if="showModalBinnacle"
      @close="showModalBinnacle = false"
      :client_information="client_information"
    />
    <add-charge
      v-if="showAddCharge"
      :action="1"
      :data="dataClient"
      :all-payment-methods="false"
      :send-from="sendFrom"
      @close="closeAddCharge"
      @refreshTable="refreshTable"
    />
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ListTrackingModal from "@/views/commons/components/applications/views/components/modals/ListTrackingModal";
import PersonalInfoModal from "@/views/commons/components/applications/views/components/modals/PersonalInfoModal.vue";
import BankInfoModal from "@/views/commons/components/applications/views/components/modals/BankInfoModal";
import StatusApp from "@/views/commons/components/applications/views/components/others/StatusApp.vue";
import StatusResult from "@/views/commons/components/applications/views/components/others/StatusResult.vue";
import StatusPaid from "@/views/commons/components/applications/views/components/others/StatusPaid.vue";
import CreateTrackingModal from "@/views/commons/components/applications/views/components/modals/CreateTrackingModal";
import Binnacle from "@/views/commons/components/applications/views/components/binnacle/Binnacle.vue";
import AddCharge from "@/views/commons/components/applications/views/components/others/AddCharge.vue";
import StatusAppServices from "@/views/commons/components/appointments/view/component/modal/StatusApp.vue";
import FormModal from "@/views/commons/components/appointments/view/component/modal/FormModal.vue";

export default {
  props: {
    typeService: {
      require: false,
      default: 1,
      type: Number,
    },
    /* client:{
      type:Object,
      require:
    } */
  },
  components: {
    PersonalInfoModal,
    ListTrackingModal,
    BankInfoModal,
    StatusApp,
    StatusResult,
    StatusPaid,
    CreateTrackingModal,
    Binnacle,
    AddCharge,
    StatusAppServices,
    FormModal,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    visibleFields() {
      return this.fields.filter((item) => item.visible);
    },
    indexFieldAmount() {
      return this.fields
        .filter((item) => item.visible)
        .findIndex((item) => item.key == "amount");
    },
  },
  data() {
    return {
      onControl: false,
      trackingController: false,
      bankInfoController: false,
      fields: [
        {
          key: "name_user",
          label: "Created by",
          visible: true,
        },
        {
          key: "name_charge",
          label: "Type",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "form",
          label: "Form",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "result",
          label: "Result",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "done_bank",
          label: "Bank info",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "paid",
          label: "Paid",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "binnacle",
          label: "Binnacle",
        },
        {
          key: "tracking",
          label: "Tracking",
          thStyle: {
            textAlign: "center",
          },
          visible: true,
        },
      ],
      trackingData: [],
      items: [],
      isBusy: false,
      total: 0,
      dataBank: [],
      idApp: "",
      statusOptions: [
        { value: null, text: "" },
        { value: 1, text: "ACCEPTED" },
        { value: 2, text: "REJECTED" },
      ],
      resultOptions: [
        { value: null, text: "" },
        { value: 0, text: "PENDING" },
        { value: 1, text: "ACCEPTED" },
        { value: 2, text: "DENIED" },
      ],
      paidOptions: [
        { value: null, text: "" },
        { value: 1, text: "YES" },
        { value: 2, text: "NO" },
      ],
      ActivePersonalInfoModal: false,
      ActiveBankInfoModal: false,
      ActiveVerificationModal: false,
      ActiveListTrackingModal: false,
      ActiveCreateTrackingModal: false,
      row: {},
      showModalBinnacle: false,
      client_information: {},
      showAddCharge: false,
      dataClient: {},
      sendFrom: "",
      showForm: false,
      clientData: {},
      disabled: false,
    };
  },
  async created() {
    this.sendFrom = {
      2: "appointments_financial",
      3: "other_services_financial",
    }[this.typeService];
    this.onControl = true;
    
    this.fields.forEach((element) => {
      if (element.key === "done_bank") {
        element.visible = this.typeService === 1;
      }
      if(element.key === 'form'){
        element.visible = [1,2].includes(this.typeService);
      }
      if(element.key === 'result'){
        element.visible = this.typeService === 1;
      }
    });
    await this.getApplicationsData();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    openPersonalInfoModal(data) {
      data.done_form ? (this.doneForm = true) : (this.doneForm = false);
      this.idApp = data.app_id;
      this.ActivePersonalInfoModal = true;
    },
    async openBankInfo(item) {
      this.dataBank = item;
      this.bankInfoController = true;
    },
    async openModalTracking(item) {
      this.idApp = item;
      this.trackingController = true;
    },
    async itemsApp() {
      await this.getApplicationsData();
      return this.items;
    },
    async getApplicationsData() {
      try {
        this.isBusy = true;
        const data = await ClientsOptionsServices.searchClientApplications({
          id: this.client.id,
          typeService: this.typeService,
        });
        if (data.status === 200) {
          this.items = data.data;
          // eslint-disable-next-line array-callback-return
          if (this.items.length !== 0) {
            this.items.map((dato) => {
              this.total = Number(dato.total).toFixed(2);
            });
          }
          this.isBusy = false;

          // return this.items
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      }
    },
    async refreshTable() {
      this.$refs.applicationsClient.refresh();
      await this.getApplicationsData();
    },

    async closePersonalInfoModal() {
      await this.getApplicationsData();
      this.ActivePersonalInfoModal = false;
    },
    openModalBinnacle(data) {
      this.showModalBinnacle = true;
      this.client_information = data;
    },
    closeListTrackingModal() {
      this.trackingController = false;
    },
    closeBankInfoModal() {
      this.bankInfoController = false;
    },
    openCreateTrackingModal(row) {
      this.row = row;
      this.row.account = this.client.account;
      this.row.name_client = this.client.client_name;
      this.ActiveCreateTrackingModal = true;
    },
    openAddCharge(data) {
      console.log("dashboard client", data);
      this.dataClient = data;
      this.showAddCharge = true;
    },
    closeAddCharge() {
      this.showAddCharge = false;
    },
    openFormModal(data) {
      this.showForm = true;
      this.clientData = data;
      this.disabled = data.app_status == 'COMPLETED';
    },
    closeFormModal() {
      this.showForm = false;
    },
  },
};
</script>
<style>
</style>
<style scoped>
.active {
  color: var(--success);
  cursor: pointer;
}
.inactive {
  color: var(--secondary);
  cursor: pointer;
}
</style>
