const { amgApi } = require("@/service/axios");

class CreditCardService {
    constructor() {
        this.routeApplication = "applications/binnacle";
    }

    async insertMessageBinnacle(params) {
        try {
            const { data } = await amgApi.post(
                `${this.routeApplication}/store-message-binnacle`,
                params
            );
            return data;
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            throw new Error(errorMessage);
        }
    }

    async getCommentsBinnacle(params) {
        try {
            const { data } = await amgApi.post(
                `${this.routeApplication}/get-comments-binnacle`,
                params
            );
            return data;
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            throw new Error(errorMessage);
        }
    }

    async uploadFilesBinnacle(formData) {
        try {
            const response = await window.amgApi.post(
                `${this.routeApplication}/upload-files-binnacle`,
                formData,
            );
            return response;
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            throw new Error(errorMessage);
        }
    }

    async getFilesBinnacle(formData) {
        try {
            const response = await window.amgApi.post(
                `${this.routeApplication}/get-files-binnacle`,
                formData,
            );
            return response;
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            throw new Error(errorMessage);
        }
    }

    async markAsSeen(formData) {
        try {
            const response = await window.amgApi.post(
                `${this.routeApplication}/mark-as-seen`,
                formData,
            );
            return response;
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            throw new Error(errorMessage);
        }
    }

    async getCommentsStatus(params) {
        try {
            const { data } = await amgApi.post(
                `${this.routeApplication}/get-comments-status`,
                params
            );
            return data;
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            throw new Error(errorMessage);
        }
    }

}

export default new CreditCardService();
