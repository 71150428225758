<template>
  <div>
    <b-modal
      v-model="ownControl"
      modal
      centered
      title="CHANGE ADVISOR"
      size="lg"
      modal-class="custom-modal-amg"
      title-tag="h3"
      header-class="p-0"
      header-bg-variant="transparent"
      :no-close-on-backdrop="true"
      @hidden="closeModalChangeAdvisor"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>CHANGE ADVISOR</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModalChangeAdvisor"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <ValidationObserver ref="form">
        <b-row>
          <!-- Advisor -->
          <b-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="select"
              rules="required"
            >
              <b-form-group label="Advisor">
                <v-select
                  v-model="advisor"
                  label="user_name"
                  :options="advisors"
                  :reduce="(val) => val.id"
                  :class="{
                    'border-danger': errors[0],
                  }"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- Message  -->
          <b-col cols="12">
            <b-form-group label="Message">
              <!-- Subject -->
              <ValidationProvider
                v-slot="{ errors }"
                name="firstname"
                rules="required"
              >
                <b-form-input
                  v-model="subject"
                  :class="{
                    'border-danger': errors[0],
                  }"
                />
              </ValidationProvider>
              <!-- Content -->
              <ValidationProvider
                v-slot="{ errors }"
                name="firstname"
                rules="required"
              >
                <b-form-textarea
                  id="message"
                  v-model="content"
                  class="mt-1"
                  rows="3"
                  placeholder="Write new message"
                  maxlength="1000"
                  :class="{
                    'border-danger': errors[0],
                  }"
                />
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <b-button @click="saveAdvisor" class="background-ce">
          <feather-icon icon="SaveIcon" class="text-white" size="15" />
          Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import vSelect from "vue-select";
// Import Services
import ClientDashboardService from "../../services/clients.dashboard.services";

export default {
  components: {
    vSelect,
  },
  props: {
    modalChangeAdvisor: {
      type: Boolean,
    },
  },
  data() {
    return {
      openModalChangeAdvisor: this.modalChangeAdvisor,
      advisors: [],
      advisor: "",
      subject: "",
      content: "",
      ownControl: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },

  mounted() {
    this.subject = `${this.client.client_name} | ${this.client.account}`;
  },
  async created() {
    this.ownControl = true;
    await this.advisorList();
  },
  methods: {
    closeModalChangeAdvisor() {
      this.$emit("close");
    },
    async advisorList() {
      try {
        let modulese = 0;
        switch (parseInt(this.client.program_id)) {
          case 1:
            modulese = 3;
            break;
          case 2:
            modulese = 7;
            break;
          case 3:
            modulese = 6;
            break;
          case 4:
            modulese = 5;
            break;
          case 5:
            modulese = 8;
            break;
          case 6:
            modulese = 10;
            break;
          case 7:
            modulese = 11;
            break;
        }

        const data = await ClientDashboardService.advisorList(modulese, {
          roles: modulese == 11 ? "[8,3,2]" : "[1,2,3,11]",
          type: "1",
        });
        this.advisors = data.data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with advisors!"
        );
        console.log(error);
      }
    },
    async saveAdvisor() {
      this.$refs.form.validate().then(async (success) => {
        if (!success) {
        } else {
          const confirm = await this.showConfirmSwal();
          if (confirm.isConfirmed) {
            try {
              const params = {
                advisor_id: this.advisor,
                id: this.$route.params.idClient,
                session_id: this.currentUser.user_id,
                subject: this.subject,
                content: this.content,
                modul: this.moduleId,
              };
              this.addPreloader();
              const response = await ClientDashboardService.changeAdvisor(
                params
              );
              this.removePreloader();
              if (response.status == 200) {
                this.$emit("refresh");
                this.showToast(
                  "success",
                  "top-right",
                  "Success!",
                  "CheckIcon",
                  "Successful operation"
                );
                this.closeModalChangeAdvisor();
              }
            } catch (error) {
              this.removePreloader();
              this.showToast(
                "danger",
                "top-right",
                "Oop!",
                "AlertOctagonIcon",
                this.getInternalErrors(error)
              );
              console.log(error);
            }
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
  color: #fff;
}
</style>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
