<template>
  <div>
    <b-modal
      v-model="ownControl"
      modal
      centered
      title="CO-APPLICANT"
      size="lg"
      scrollable
      modal-class="custom-modal-amg"
      header-bg-variant="transparent"
      header-class="p-0"
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="closeModalApplicant"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>CO-APPLICANT</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModalApplicant"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <b-row class="client-info mx-0">
        <!-- Client -->
        <b-col cols="6" class="p-0">
          <div
            class="mr-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Account | {{ client.account }}</p>
          </div>
        </b-col>
        <b-col cols="6" class="p-0">
          <div
            class="ml-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Client | {{ client.client_name }}</p>
          </div>
        </b-col>
        <!-- <b-col cols="6" /> -->
      </b-row>
      <b-row>
        <b-col cols="6">
          <span>CO-APPLICANT PERSONAL DATA</span>
        </b-col>
      </b-row>
      <ValidationObserver ref="form">
        <!-- First Row -->
        <b-row>
          <b-col cols="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="firstname"
              rules="required"
            >
              <b-form-group label="First Name">
                <b-form-input
                  v-model="form.c_first_name"
                  :class="{
                    'border-danger': errors[0],
                  }"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Middle Name">
              <b-form-input v-model="form.c_middle_name" />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="lastname"
              rules="required"
            >
              <b-form-group label="Last Name">
                <b-form-input
                  v-model="form.c_last_name"
                  :class="{
                    'border-danger': errors[0],
                  }"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Legal Status">
              <v-select
                v-model="form.c_state_lead"
                label="name"
                :options="states_leads"
                :reduce="(val) => val.id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Second Row -->
        <b-row>
          <b-col cols="3">
            <b-form-group label="DOB">
              <kendo-datepicker
                v-model="form.c_dob"
                v-mask="'##/##/####'"
                :class="[
                  'w-100 rounded bg-transparent k-picker-custom',
                  { 'text-white': isDarkSkin },
                ]"
                :format="'MM/dd/yyyy'"
                class="leads-datepicker"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="SSN">
              <b-form-input
                v-model="form.c_ssn"
                v-mask="'###-##-####'"
                style="height: 30px"
                maxlength="11"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Civil Status">
              <v-select
                v-model="form.c_civil"
                label="name"
                :options="states_civil"
                :reduce="(val) => val.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Dependents">
              <b-form-input v-model="form.c_dependents" />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Third Row -->
        <b-row>
          <b-col cols="3">
            <b-form-group label="Employer">
              <b-form-input v-model="form.c_employer" />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Phone number">
              <b-form-input v-model="form.c_phone" v-mask="'(###) ###-####'" />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Monthly Net Income">
              <money
                id="price"
                v-model="form.c_monthly"
                v-bind="moneyConfig"
                name="price"
                class="form-control"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Fouth Row -->
        <b-row>
          <b-col cols="9">
            <b-form-group label="Mailing Address">
              <vue-google-autocomplete
                id="address_principal"
                ref="addressprincipal"
                v-model="form.street"
                class="form-control input-form"
                placeholder="Please type your address"
                country="us"
                @placechanged="getAddressData"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="City">
              <b-form-input v-model="form.city" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group label="State">
              <v-select
                v-model="form.state"
                label="state"
                :options="states"
                :reduce="(val) => val.slug"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Zip Code">
              <b-form-input v-model="form.zipcode" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Country">
              <b-form-input v-model="form.country" />
            </b-form-group>
          </b-col>
        </b-row>
      </ValidationObserver>

      <!-- Footer -->
      <template #modal-footer>
        <b-button v-if="valoCo" variant="danger" @click="trashCoApp">
          <template v-if="isLoading">
            <b-spinner small />
            <span>Loading...</span>
          </template>
          <span v-else>
            <feather-icon icon="TrashIcon" class="text-white" size="15" />
            Delete
          </span>
        </b-button>
        <b-button class="background-ce" @click="saveCoApp">
          <template v-if="isLoading">
            <b-spinner small />
            <span>Loading...</span>
          </template>
          <span v-else>
            <feather-icon icon="SaveIcon" class="text-white" size="15" />
            Save
          </span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import VueGoogleAutocomplete from "vue-google-autocomplete";
// Import Services
import { mapGetters } from "vuex";
import ClientDashboardService from "../../services/clients.dashboard.services";

export default {
  components: {
    vSelect,
    VueGoogleAutocomplete,
  },
  props: {
    valoCo: {
      type: Boolean,
    },
  },
  data() {
    return {
      ownControl: false,
      isLoading: false,
      moneyConfig: {
        decimal: ",",
        thousands: ".",
        prefix: "$",
        suffix: "",
        precision: 2,
        masked: false,
      },
      form: {
        c_first_name: "",
        c_dob: "",
        c_employer: "",
        c_middle_name: "",
        c_ssn: "",
        c_phone: "",
        c_last_name: "",
        c_state_lead: "",
        c_civil: "",
        c_dependents: "",
        c_monthly: "",
        street: "",
        city: "",
        state: "",
        zipcode: "",
        country: "EE.UU.",
      },
      states_leads: [],
      states_civil: [
        { value: "Single", name: "Single" },
        { value: "Engaged", name: "Engaged" },
        { value: "Married", name: "Married" },
        { value: "Divorced", name: "Divorced" },
        { value: "Widow/er", name: "Widow/er" },
      ],
      states: [],
      id_analisis: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
  },
  async created() {
    this.ownControl = true;
    await Promise.all([
      this.statesLead(),
      this.stateEEUU(),
      this.analisisDebsolution(),
    ]);
  },
  methods: {
    closeModalApplicant() {
      this.$emit("close");
    },
    async statesLead() {
      try {
        const response = await ClientDashboardService.stateLeads();
        this.states_leads = response.data;
        return this.states_leads;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with Leads!"
        );
        console.log(error);
      }
    },
    async stateEEUU() {
      try {
        const response = await ClientDashboardService.stateEEUU();
        this.states = response.data;
        return this.states;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with states!"
        );
        console.log(error);
      }
    },
    getAddressData(address_principal) {
      this.direccion = address_principal;
      this.address_principal = `${this.direccion.street_number} ${this.direccion.route}`;
      this.form.street = this.address_principal; //
      this.form.state = this.direccion.administrative_area_level_1;
      this.form.city = this.direccion.locality;
      this.form.zipcode = this.direccion.postal_code;
    },
    async analisisDebsolution() {
      try {
        this.addPreloader();
        if (this.id_analisis != null) {
          const response = await ClientDashboardService.getCoApplicant({
            id: this.$route.params.idClient,
          });

          if (response.status === 200 && response.data.length !== 0) {
            this.form.c_first_name = response.data[0].c_name;
            this.id_analisis = response.data[0].id_analisis;
            this.form.c_middle_name = response.data[0].c_middle;
            this.form.c_last_name = response.data[0].c_last;
            this.form.c_dob = response.data[0].c_dob;
            this.form.c_ssn = response.data[0].c_ssn;
            this.form.c_civil = response.data[0].c_status_civil;
            this.form.c_dependents = response.data[0].c_dependents;
            this.form.c_employer = response.data[0].c_employer;
            this.form.c_phone = response.data[0].c_phone_work;
            this.form.c_monthly =
              response.data[0].c_applicant_monthly != null
                ? response.data[0].c_applicant_monthly
                : "";
            this.form.c_state_lead = response.data[0].c_status_lead;
            this.form.street = response.data[0].street;
            this.form.city = response.data[0].city;
            this.form.zipcode = response.data[0].zipcode;
            this.form.country =
              response.data[0].country != null
                ? response.data[0].country
                : this.country;
            this.form.state = response.data[0].states;
          }
          this.removePreloader();
        }
      } catch (error) {
        this.removePreloader();
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with Information Applicant!"
        );
      }
    },
    async saveCoApp() {
      this.$refs.form.validate().then(async (success) => {
        if (!success) {
        } else {
          const confirm = await this.showConfirmSwal(
            "ARE YOU SURE OF CONTINUE ?",
            "Before finalizing you must save."
          );
          if (confirm.isConfirmed) {
            try {
              const params = {
                analisis: this.id_analisis,
                account: this.$route.params.idClient,
                monthly: this.form.c_monthly,
                f_name: this.form.c_first_name,
                m_name: this.form.c_middle_name,
                l_name: this.form.c_last_name,
                s_lead: this.form.c_state_lead,
                dob: this.form.c_dob,
                ssn: this.form.c_ssn,
                employer: this.form.c_employer,
                phone: this.form.c_phone,
                civil: this.form.c_civil,
                depends: this.form.c_dependents,
                id_user: this.currentUser.user_id,
                valor_btn: this.valoCo,
                street: this.form.street,
                city: this.form.city,
                zipcode: this.form.zipcode,
                country: this.form.country,
                state: this.form.state,
              };
              this.addPreloader();
              const data = await ClientDashboardService.setCoApplicant(params);
              if (data.status === 200) {
                this.$emit("refresh");
                this.removePreloader();
                this.closeModalApplicant();
                this.showToast(
                  "success",
                  "top-right",
                  "Success!",
                  "CheckIcon",
                  "Successful operation"
                );
              }
            } catch (error) {
              console.log(error);
              this.removePreloader();
              this.showToast(
                "danger",
                "top-right",
                "Oop!",
                "AlertOctagonIcon",
                this.getInternalErrors(error)
              );
            }
          }
        }
      });
    },
    async trashCoApp() {
      const confirm = await this.showConfirmSwal(
        "ARE YOU SURE OF CONTINUE ?",
        "Before finalizing you must save."
      );
      if (confirm.isConfirmed) {
        try {
          const params = {
            analisis: this.id_analisis,
            account: this.$route.params.idClient,
            monthly: "",
            f_name: "",
            m_name: "",
            l_name: "",
            s_lead: "",
            dob: "",
            ssn: "",
            employer: "",
            phone: "",
            civil: "",
            depend: "",
            id_user: this.currentUser.user_id,
          };
          this.addPreloader();
          const data = await ClientDashboardService.setCoApplicant(params);
          this.$emit("refresh");
          this.removePreloader();
          this.closeModalApplicant();
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
        } catch (error) {
          console.log(error);
          this.removePreloader();
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            this.getInternalErrors(error)
          );
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
