var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"modal":"","centered":"","title":"CHANGE ADVISOR","size":"lg","modal-class":"custom-modal-amg","title-tag":"h3","header-class":"p-0","header-bg-variant":"transparent","no-close-on-backdrop":true},on:{"hidden":_vm.closeModalChangeAdvisor},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"header-modal"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v("CHANGE ADVISOR")])]),_c('div',{staticClass:"container-icons",class:_vm.isDarkSkin ? 'container-icons--dark' : '',on:{"click":_vm.closeModalChangeAdvisor}},[_c('feather-icon',{staticClass:"pointer",class:_vm.isDarkSkin ? 'text-light' : 'text-primary',attrs:{"icon":"XIcon","size":"18"}})],1)])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"background-ce",on:{"click":_vm.saveAdvisor}},[_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"SaveIcon","size":"15"}}),_vm._v(" Save ")],1)]},proxy:true}]),model:{value:(_vm.ownControl),callback:function ($$v) {_vm.ownControl=$$v},expression:"ownControl"}},[_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"select","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Advisor"}},[_c('v-select',{class:{
                  'border-danger': errors[0],
                },attrs:{"label":"user_name","options":_vm.advisors,"reduce":function (val) { return val.id; }},model:{value:(_vm.advisor),callback:function ($$v) {_vm.advisor=$$v},expression:"advisor"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Message"}},[_c('ValidationProvider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{class:{
                  'border-danger': errors[0],
                },model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"mt-1",class:{
                  'border-danger': errors[0],
                },attrs:{"id":"message","rows":"3","placeholder":"Write new message","maxlength":"1000"},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }