<template>
  <div>
    <b-modal
      v-model="ownControl"
      modal
      centered
      title="TRACKING CO-APPLICANT"
      size="lg"
      modal-class="custom-modal-amg"
      header-bg-variant="transparent"
      header-class="p-0"
      hide-footer
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="closeModalHistory"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>TRACKING CO-APPLICANT</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModalHistory"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="client-info">
          <b-col cols="6" class="p-0">
            <div
              class="mr-1"
              :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
            >
              <p>Client | {{ client.client_name }}</p>
            </div>
          </b-col>
          <b-col cols="6" class="p-0">
            <div
              class="ml-1"
              :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
            >
              <p>Program | {{ client.program_name }}</p>
            </div>
          </b-col>
        </b-row>
        <!-- Table -->
        <b-table
          ref="refClientsList"
          :items="searchHistoryCoApplicant"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          class="table-new-customization custom-table"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(action)="data">
            <div>
              {{ data.item.type }}
            </div>
          </template>

          <template #cell(user)="data">
            <div class="row align-items-center">
              <div class="col-2 p-0">
                <b-avatar
                  variant="info"
                  :src="
                    data.item.user_image_created
                      ? data.item.user_image_created
                      : require('@/assets/images/logo/amg_logo.svg')
                  "
                ></b-avatar>
              </div>
              <div class="col-9 px-1">
                <span>{{ data.item.created_name_user }}</span>
              </div>
            </div>
          </template>
          <template #cell(date)="data">
            <div>
              {{ data.item.updated_at | myGlobalDay }}
            </div>
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
// Import Services
// eslint-disable-next-line import/no-unresolved
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isBusy: false,
      ownControl: false,
      historys: [],
      fields: [
        { key: "action", label: "Action" },
        { key: "user", label: "User" },
        { key: "date", label: "Date" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
  },
  created() {
    this.ownControl = true;
  },

  methods: {
    closeModalHistory() {
      this.$emit("close");
    },

    async searchHistoryCoApplicant() {
      try {
        this.isBusy = true;
        const data = await ClientDashboardService.getHistoryCoApplicant({
          id: this.$route.params.idClient,
        });
        this.isBusy = false;
        this.historys = data.data;
        return this.historys;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with History!"
        );
        console.log("something went wrong with historyStatus", error);
      }
    },
  },
};
</script>
<style scoped>
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
  color: #fff;
}
</style>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
