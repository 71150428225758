<template>
  <div>
    <b-modal
      ref="scoreModal"
      v-model="ownControl"
      title="ADD SCORES"
      title-tag="h3"
      @hidden="closeModal"
    ><div>

       <!-- Titles -->
       <b-row class="mt-1 px-">
         <!-- Program -->
         <b-col cols="6">
           <h5 class="text-left">
             Program
           </h5>
           <p
             class="rounded text-primary border-primary font-medium-1 text-center py10"
           >
             {{ client.program_name }}
           </p>
         </b-col>
         <!-- Client -->
         <b-col cols="6">
           <h5 class="text-left">
             Client
           </h5>
           <p
             class="rounded text-primary border-primary font-medium-1 text-center py10"
           >
             {{ client.client_name }}
           </p>
         </b-col>
       </b-row>
       <validation-observer>
         <b-row>
           <b-col lg="5"><validation-provider name="Date">
             <b-form-group label="Date">
               <kendo-datepicker
                 v-model="date"
                 v-mask="'##/##/####'"
                 :class="['w-100 rounded bg-transparent k-picker-custom', {'text-white':isDarkSkin}]"
                 :format="'MM/dd/yyyy'"
                 class="leads-datepicker"
               />
             </b-form-group>
           </validation-provider></b-col>
         </b-row>

         <b-row>
           <b-col lg="4"><validation-provider name="EQUIFAX">
             <b-form-group>
               <template #label>
                 <span style="color:#822a3a;font-weight: bold;">EQUIFAX</span>
               </template>
               <b-form-input
                 v-model="equifax"
                 style="color:#822a3a;font-weight: bold;"
               />
             </b-form-group>
           </validation-provider></b-col>
           <b-col lg="4"><validation-provider name="Experian">
             <b-form-group>
               <template #label>
                 <span style="color:#0566b7;font-weight: bold;">Experian</span>
               </template>
               <b-form-input
                 v-model="experian"
                 style="color:#0566b7;font-weight: bold;"
               />
             </b-form-group>
           </validation-provider></b-col>
           <b-col lg="4"><validation-provider name="transunion">
             <b-form-group>

               <template
                 #label
                 style="font-weight: bold;"
               >
                 <span>Trans</span> <span style="color:#4d917a">Union</span>
               </template>

               <b-form-input
                 v-model="transunion"
                 style="color:#4d917a;font-weight: bold;"
               />
             </b-form-group>
           </validation-provider></b-col>
         </b-row>
       </validation-observer>

     </div>
      <template #modal-footer>
        <b-button
          variant="primary"
          title="Save Score"
          @click="savescore"
        >
          <feather-icon
            icon="SaveIcon"
            style="margin-right: 3px"
          /> Save
        </b-button>

      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientDashboardService from '@/views/commons/components/clients/dashboard/services/clients.dashboard.services'

export default {
  props: {
    idscore: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      ownControl: false,
      min: new Date(1950, 0, 1, 0, 0, 0),
      max: new Date(2049, 11, 31, 24, 0, 0),
      minDate: new Date(1000, 1, 1),
      maxDate: new Date(2050, 9, 1),
      date: '',
      equifax: '',
      experian: '',
      transunion: '',
    }
  },
  computed: {

    ...mapGetters({
      client: 'DebtSolutionClients/G_CLIENTS',
      currentUser: 'auth/currentUser',
    }),
  },
  async created() {
    this.ownControl = true
    if (this.idscore) {
      await this.getEditScore()
    }
  },
  methods: {
    closeModal() {

      this.$emit('close')

    },

    async savescore() {
      const confirm = await this.showConfirmSwal()
      if (confirm.isConfirmed) {
        try {
          const params = {

            id: this.client.lead_id,
            idscore: this.idscore ? this.idscore : '',
            date: this.date,
            equifax: this.equifax,
            experian: this.experian,
            transunion: this.transunion,
            user_id: this.currentUser.user_id,

          }
          const data = await ClientDashboardService.saveScore(params)
          if (data.status === 200) {
            this.$emit('refresh')
            this.$refs.scoreModal.hide()
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    async getEditScore() {
      const params = {
        idscore: this.idscore,

      }
      try {
        const data = await ClientDashboardService.editScore(params)
        if (data.status === 200) {
          this.date = data.data[0].date
          this.equifax = data.data[0].equifax
          this.experian = data.data[0].experian
          this.transunion = data.data[0].transunion
        }


      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },

}
</script>

<style scoped>

</style>
